import { Layout, Typography,Steps,Col,Row,Image } from "antd";
import { RocketOutlined, PrinterOutlined, SolutionOutlined, BarsOutlined } from '@ant-design/icons';
import {useLocation} from 'react-router-dom';

const { Title } = Typography;
const headerStyle = {
    textAlign: 'center',
    color: '#fff',
    height: 70,
    paddingInline: 50,
    lineHeight: '64px',
    backgroundColor: '#488ca4',
  };
  const navBarStyle = {
    textAlign: 'center',
    color: '#ffff',
    height:110,
    paddingInline: 50,
    lineHeight: '64px',
    backgroundColor: '#94b4cc',
  };

export default function Header() {
    const currPath = useLocation()
    const index = () => {
        switch(currPath.pathname){
            case "/register-parcels":
                return 0
            case "/collection-details":
                return 1
            case "/confirm-collection":
                return 2
            case "/documents":
                return 3
            default:
                return null
            
    
        }
    }
    return (    
        <div>
            <Layout style={headerStyle}>
                <Image src={"./SampleLogistics.png"}  width={115} preview={false} />
            </Layout>
            <Layout style={navBarStyle}>
                <Row>
                    <Col span={4}>
                        <Title level={1} style={{color:'white'}}>SampleShip</Title>
                    </Col>
                    <Col span={20}>
                        <Steps type="navigation" current={index()} style={{marginTop: 30, background:'#F5F5F5', borderRadius: "12px"}}
                            items={[
                            {
                                title: 'Register Samples',
                                icon: <BarsOutlined />,
                            },
                            {
                                title: 'Enter Collection Details',
                                icon: <SolutionOutlined />,
                            },
                            {
                                title: 'Confirm Shipment Details',
                                icon:<RocketOutlined />
                            },
                            {
                                title: 'Print Documents',
                                icon: <PrinterOutlined />,
                            },
                            ]}
                        />
                    </Col>
                </Row>
            </Layout>
        </div>
    );
  }