import { useRouteError,useNavigate } from "react-router-dom";
import Header from './components/header'
import Footer from "./components/footer";
import { Layout,Row,Col, Button } from "antd";
import { Content } from "antd/es/layout/layout";
import Title from "antd/es/skeleton/Title";
import {RollbackOutlined} from '@ant-design/icons';
export default function ErrorPage() {
  const error = useRouteError();
  const navigate = useNavigate();
  console.log(error)
  return (
  <Content>    
    <Row>
      <Col span={9} />
      <Col span={11}> <h1>Oops! Something went wrong!</h1></Col>
    </Row>
    <Row>
    <Col span={9} />
      <Col span={12}> 
      <p>Sorry, an unexpected error has occurred.</p>
      <p></p>
      <p>
        <i><strong>{error.status} {error.statusText || error.message}</strong></i>
      </p> 
      <Button type='primary'  icon={<RollbackOutlined />} onClick={() => {navigate('/welcome')}}>Home</Button>
      </Col>
    </Row>
    </Content>

  );
}