import { Layout, Row,Col, Button } from 'antd';
import Header from '../components/header.js'
import Footer from '../components/footer.js';
import Typography from 'antd/es/typography/Typography.js';
import { useNavigate } from "react-router-dom";
import Title from 'antd/es/skeleton/Title.js';
const { Content} = Layout

const contentStyle = {
    minHeight: "100%",
    lineHeight: '60px',
    color: '#fff',
    height: "calc(100vh - 24vh)",
    textAlign: 'center'
  };

export default function Root() {
    const navigate = useNavigate();
    // useEffect(() => {
    //     fetch('http://localhost:3000/parcels.json').then(res => {console.log(res.json())})

    // })
    return (        
    <Layout>
        <Header />
        <Layout style={contentStyle}>
            <Content>
            <Row>
                <Col span={24} style={{marginTop:30}}>
                    <Typography.Title> 🚧 We'll be back soon! 🛠️</Typography.Title>
                </Col>
            </Row>
            <Row>
                <Col span={2}/>
                <Col span={20}>
                    <Typography>Sorry for the inconvenience but we’re performing some essential maintenance at the moment. If you require assistance, please contact us on <a href='mailto:contact@samplelogisitcs.com'>contact@samplelogistics.com</a>, otherwise we’ll be back online shortly! — Sample Logistics Team</Typography>
                </Col>
            </Row>
            </Content>
        </Layout>
        <Footer />
    </Layout>
    );
  }