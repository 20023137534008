import { Layout, Input, Button, Divider,Form,Alert } from 'antd';
import Search from "antd/es/input/Search";
import Header from '../components/header.js'
import Footer from '../components/footer.js';
import Typography from 'antd/es/typography/Typography.js';
import VesselDetails from '../components/vesselDetailsCard.js'
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";

const { Title } = Typography;
const {Sider, Content} = Layout
const contentStyle = {
  minHeight: "100%",
  lineHeight: '60px',
  color: '#fff',
  height: "calc(100vh - 24vh)"
};
const siderStyle = {
    lineHeight: '120px',
    color: '#fff',
    backgroundColor: '#f5f5f5',
    marginTop: 30
  };

export default function RegisterPackages() {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const vesselSearch = Form.useWatch('vesselSearch', form);
  const vessel = Form.useWatch('vessel_name', form);
  const imo = Form.useWatch('vessel_imo', form);
  const [parcelRes, setParcelRes] = useState()
  const [vesselRes, setVesselRes] = useState()
  const [active] = useState()
  const [used] = useState()
  const [show,setShow] = useState(false)
  const [buttonDisabled,setButtonDisabled] = useState(true)
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [parcelType, setParcelType] = useState()
  
  useEffect(() => {
    // if (parcel && parcel.length === 8){
    //   sessionStorage.setItem('parcelNumber', parcel)
    //   fetch(`${process.env.REACT_APP_API_URL}/register-parcels?parcel=${parcel}`).then(res => res.json()
    //   .then(p => {
    //     if (p.error){
    //       setButtonDisabled(true)
    //       setError(true)
    //       setErrorMessage(p.error)
    //     } else {
    //       setParcelRes(p); setParcelType(p.parcel.owner); setShow(true);setButtonDisabled(false);
    //     }
    //   }))
    // } else {
    //   setError(false)
    //   setShow(false)
    //   setButtonDisabled(true)
    //   setParcelRes()
    // }
  },[active,error,errorMessage,used])
  

  function onFinish(){
      var parcel = {}
      parcel.vessel = {name: vesselRes.vessel.name, imo: vesselRes.vessel.imo}  
      parcel.client = vesselRes.client
      navigate('/collection-details', { state: {parcel: parcel, showVessel: show, noParcel:true}});
  }

  function searchVessel(){
    fetch(`${process.env.REACT_APP_API_URL}/register-packages?vessel=${vesselSearch}&system=sampleship.com`).then(res => res.json())
      .then(p => {
        if (p.vessel && p.vessel.error){
          setButtonDisabled(true)
          setError(true)
          setErrorMessage(p.vessel.error)
          setShow(false)
        } else {
          sessionStorage.setItem('noParcel', true)
          sessionStorage.setItem('vesselId', p.vessel.vessel.id)
          sessionStorage.setItem('vesselImo', p.vessel.vessel.imo)
          setVesselRes(p.vessel); setParcelType('vessel'); setShow(true);setButtonDisabled(false);
        }
      })
  }
  return (        
    <Layout>
        <Header />
        <Layout style={contentStyle} hasSider>
            <Sider width={420} style={siderStyle}><VesselDetails registerPackage={true} parcelType={parcelType} data={vesselRes} show={show} /></Sider>
            <Content style={{marginLeft: 10}}>
                <Title level={2}>Register Package(s)</Title>
                <Divider />
                <Typography>Search your Vessel Name/IMO</Typography>
                <Form form={form} onFinish={onFinish} onFinishFailed={(e) => {console.log(e)}}>
                <Form.Item name='vesselSearch'><Search onSearch={searchVessel} style={{width:"20%"}} /></Form.Item>
               {(error ? <Alert style={{maxWidth:700}} type='error' message={errorMessage} showIcon/>:null)}
               <Button style={{marginTop:10}} htmlType="submit" disabled={buttonDisabled}>Next</Button>
               </Form>
               <a onClick={() => {navigate('/register-parcels')}}>Sending with a parcel number?</a>
               <Typography>If you require any assistance please contact us at <a href='mailto:contact@samplelogistics.com'>contact@samplelogistics.com</a> and we will be happy to help.</Typography>
            </Content>
        </Layout>
        <Footer />
    </Layout>
    );
  }